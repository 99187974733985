<template>
	<div>
		<form @submit.prevent="checkForm" id="addTiers"  autocomplete="off">
			<div class="row">
				<div class="col-12" v-if="form_message !== ''">
					<ErrorAlert :messageI18n="form_message" />
				</div>
			</div>
			<div class="row">
				<div class="col-6">
					<div class="form-group" v-if="form.tiers_moral == true">
						<b-form-checkbox
							v-model="form.tiers_moral"
							:unchecked-value="false"
						>
						{{ $t('tiers.is_moral') }}
						</b-form-checkbox>
					</div>
					<div class="form-group" v-else>
						<b-form-checkbox
							v-model="form.tiers_moral"
							:unchecked-value="true"
						>
						{{ $t('tiers.is_moral') }}
						</b-form-checkbox>
					</div>
				</div>
				<div class="col-auto">
					<div class="form-group" v-if="add_contact == true">
						<b-form-checkbox
							v-model="add_contact"
							:unchecked-value="false"
						>
						{{ $t('tiers.add_contact') }}
						</b-form-checkbox>
					</div>
					<div class="form-group" v-else>
						<b-form-checkbox
							v-model="add_contact"
							:unchecked-value="true"
						>
						{{ $t('tiers.add_contact') }}
						</b-form-checkbox>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-12">
					<WarnAlert v-if="tier_already_exists" messageI18n="tiers.already_exist" />
				</div>
			</div>
			<div class="row" v-if="form.tiers_moral == true">
				<label for="tiers_rs"  class="col-sm-4 col-form-label">{{ $t("tiers.rs") }} *</label>
				<div class="col-sm-8"><input type="text" id="tiers_rs" class="form-control" v-model="form.tiers_rs" @blur="checkIdenticalTierRs" required  autocomplete="chrome-off" :class="{ 'is-invalid': error && error.indexOf('Erreur rs') > -1 }"></div>
			</div>
			<div class="row mt-2" v-if="form.tiers_moral == true && add_contact">
				<label class="col-sm-4 col-form-label">
					{{ $t('tiers.info_contact') }}
				</label>
				<div class="col-sm-2">
					<e-select
						id="tiers_civility"
						v-model="contact_form.contact_civility"
						:options="civilities"
						:allow-empty="false"
						:show-labels="false"
						:class="{ 'is-invalid': error && error.indexOf('Erreur civilité') > -1 }"
					/>
				</div>

				<div class="col-sm-3"><input type="text" id="tiers_firstname" class="form-control" v-bind:placeholder="$t('tiers.firstname')" v-model="contact_form.contact_firstname" required  autocomplete="chrome-off" :class="{ 'is-invalid': error && error.indexOf('Erreur prénom') > -1 }"></div>
				<div class="col-sm-3"><input type="text" id="tiers_lastname" class="form-control" v-bind:placeholder="$t('tiers.lastname')" v-model="contact_form.contact_lastname" required  autocomplete="chrome-off" :class="{ 'is-invalid': error && error.indexOf('Erreur nom') > -1 }"></div>
			</div>
			<div class="row" v-if="!form.tiers_moral">
				<div class="col-sm-2">
					<e-select
						id="tiers_civility"
						v-model="form.tiers_civility"
						:options="civilities"
						:allow-empty="false"
						:show-labels="false"
						:class="{ 'is-invalid': error && error.indexOf('Erreur civilité') > -1 }"
					/>
				</div>

				<div class="col-sm-5"><input type="text" id="tiers_firstname" class="form-control" v-bind:placeholder="$t('tiers.firstname')" v-model="form.tiers_firstname" @blur="checkIdenticalTierRs" required  autocomplete="chrome-off" :class="{ 'is-invalid': error && error.indexOf('Erreur prénom') > -1 }"></div>
				<div class="col-sm-5"><input type="text" id="tiers_lastname" class="form-control" v-bind:placeholder="$t('tiers.lastname')" v-model="form.tiers_lastname" @blur="checkIdenticalTierRs" required  autocomplete="chrome-off" :class="{ 'is-invalid': error && error.indexOf('Erreur nom') > -1 }"></div>
			</div>
	

			<div class="row mt-2">
				<label for="tiers_alias" class="col-sm-4 col-form-label">{{ $t("tiers.alias") }}</label>
				<div class="col-sm-8"><input type="text" id="tiers_alias" class="form-control" v-model="form.tiers_alias" required  autocomplete="chrome-off"></div>
			</div>
			
			<div class="row" v-if="mapbox">
				<div class="col-12">
					<e-address 
						:address.sync="form.tiers_address1"
						:postalCode.sync="form.tiers_postalcode"
						:city.sync="form.tiers_town"
						:country.sync="form.tiers_country"
					/>
				</div>
			</div>
			<div class="row mt-2">
				<label for="tiers_address1" class="col-sm-4 col-form-label">{{ $t("tiers.address1") }}</label>
				<div class="col-sm-8">
					<input type="text" id="tiers_address1" class="form-control" v-model="form.tiers_address1" required  autocomplete="chrome-off">
					<input type="text" id="tiers_address2" class="mt-2 form-control" v-model="form.tiers_address2" required  autocomplete="chrome-off">
				</div>
			</div>		
			<div class="row mt-2">
				<label for="tiers_postalcode" class="col-sm-4 col-form-label">{{ $t("tiers.postalcode") }}</label>
				<div v-if="is_french" class="col-sm-8">
					<input type="text" id="tiers_postalcode" class="form-control" v-if="form.tiers_postalcode" v-model="form.tiers_postalcode" required  autocomplete="chrome-off" @click="resetPostalcode">
					<SearchInputCommune v-if="isOnline && !form.tiers_postalcode" :town="0" :tiers_postalcode.sync="form.tiers_postalcode" :tiers_town.sync="form.tiers_town"/>
				</div>
				<div v-else class="col-sm-8">
					<input type="text" id="tiers_postalcode_nofrench" class="form-control" v-model="form.tiers_postalcode" required />
				</div>
			</div>	
			<div class="row mt-2">						
				<label for="tiers_town" class="col-sm-4 col-form-label">{{ $t("tiers.town") }}</label>
				<div v-if="is_french" class="col-sm-8">
					<input type="text" id="tiers_town" class="form-control" v-if="form.tiers_town" v-model="form.tiers_town" required autocomplete="chrome-off" @click="resetTown">
					<SearchInputCommune v-if="isOnline && !form.tiers_town" :town="1" :tiers_postalcode.sync="form.tiers_postalcode" :tiers_town.sync="form.tiers_town"/>
				</div>
				<div v-else class="col-sm-8">
					<input type="text" id="tiers_town_nofrench" class="form-control" v-model="form.tiers_town" required />
				</div>
			</div>
			<div class="row mt-2">						
				<label for="tiers_country" class="col-sm-4 col-form-label">{{ $t("tiers.country") }} *</label>
				<div class="col-sm-8"><CountryInput v-model="form.tiers_country"></CountryInput></div>
			</div>

			<div class="row mt-2">
				<label for="tiers_mail" class="col-sm-4 col-form-label">{{ $t("tiers.email") }}</label>
				<div class="col-sm-8">	
					<input type="email" id="tiers_mail" class="form-control" v-model="form.tiers_mail"  autocomplete="chrome-off" :class="{ 'is-invalid': error && error.indexOf('Erreur mail') > -1 }">
				</div>
			</div>

			<div class="row mt-2">
				<div class="col-12 phone mb-3" v-for="(phone, key_phone) in form.phones" v-bind:key="key_phone">
					<div class="row no-gutters">
						<div class="col-auto pr-2">
							<phoneInput v-model="form.phones[key_phone]"></phoneInput>
						</div>
						<div class="col pr-2">
							<div class="input-group">
								<div class="input-group-prepend">
									<b-form-input type="text" class="form-control" style="width: 5em;" v-model="phone.phone_indicatif"/>
								</div>
								<input type="text" class="form-control" v-model="phone.phone_numero" required  autocomplete="chrome-off">
							</div>
						</div>
						<div class="col-3">
							<e-select
								id="phone_type"
								v-model="phone.phone_type"
								:options="[
									$t('tiers.phone_type.fixe'),
									$t('tiers.phone_type.portable')
								]"
								:allow-empty="false"
								:show-labels="false"
							/>
						</div>
					</div>
				</div>
				<div class="col-12 text-right">
					<a href="#" @click.prevent="addPhoneNumber()">{{ $t('global.ajouter_numero_telephone') }} <font-awesome-icon :icon="['fal', 'phone-plus']"/></a>
				</div>
			</div>
			
			<div class="row mt-2">						
				<label for="tiers_website" class="col-sm-4 col-form-label">{{ $t("tiers.website") }}</label>
				<div class="col-sm-8">	
					<input type="text" id="tiers_website" class="form-control" v-model="form.tiers_website" required  autocomplete="chrome-off">
				</div>
			</div>

			<div class="row mt-2">
				<label for="tiers_lang" class="col-sm-4 col-form-label">{{ $t("tiers.lang") }}</label>
				<div class="col-sm-8">
					<LocaleInput id="tiers_lang" v-model="form.tiers_lang" />
				</div>
			</div>
			<div class="text-center mt-2">
				<b-button variant="primary" @click.prevent="checkForm"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /> {{ $t("global.ajouter") }} <font-awesome-icon :icon="['fal', 'user-plus']"/></b-button>
			</div>

			<div class="row mt-2">		
				<div class="col-12">
					<div class="form-group">
						<b-form-checkbox
							v-model="form.tiers_entity"
							:value="1"
							:unchecked-value="0"
						>
						{{ $t('tiers.is_entity') }}
						</b-form-checkbox>
					</div>
				</div>
			</div>
			<div class="row mt-2">
				<div v-if="form.tiers_entity" class="col-12">
					<div class="form-group">
						<label for="tiers_currency" class="col-form-label">{{ $t("tiers.currency") }}</label>
						<CurrencyInput id="tiers_currency" v-model="form.tiers_currency" />
					</div>
				</div>
			</div>
		</form>
	</div>
</template>

<script type="text/javascript">
import Vue from 'vue'
	
	import Navigation from "@/mixins/Navigation.js"
	import Tools from "@/mixins/Tools.js"
	import Tiers from '@/mixins/Tiers.js'
	import Contact from '@/mixins/Contact.js'
	import Phone from '@/mixins/Phone.js'
    import Countries from '@/assets/lang/countries/countries'
    import cloneDeep from 'lodash/cloneDeep'
	import Shutter from "@/mixins/Shutter.js"
	import Common from '@/assets/js/common.js'
	import Indicatif from 'GroomyRoot/assets/lang/countries/indicatif'

	export default {
		name: "TierFormAdd",
		props: ['accountingplan_id', 'from', 'tiers', 'callback'],
		mixins: [Navigation, Tools, Tiers, Shutter, Contact, Phone],
		data () {
			return {
				processing: false,
				error: [],
                form_message: "",
                tier_already_exists: false,
				form: {},
				contact_form: {},
				countries: {},
				country_choosen: false,
				civilities: Common.getAllCivilities(),
				add_contact: false,
				is_french: false,
				default_form: {
					tiers_rs: '',
					tiers_firstname: '',
					tiers_lastname: '',
					tiers_civility: this.getTrad('civility.m'),
					tiers_mail: '',
					tiers_alias: '',
					tiers_address1: '',
					tiers_address2: '',
					tiers_postalcode: '',
					tiers_town: '',
					tiers_country: this.getConfig('country', '75'),
					tiers_website: '',
					tiers_entity: false,
					tiers_moral: true,
					tiers_currency: 'EUR',
					tiers_lang: Vue.i18n.locale(),
					phones: [
						{
							phone_indicatif: '+33',
							phone_numero: '',
							phone_type: this.getTrad('tiers.phone_type.fixe'),
							phone_country: "FRA",
						},
						{
							phone_indicatif: '+33',
							phone_numero: '',
							phone_type: this.getTrad('tiers.phone_type.portable'),
							phone_country: "FRA",
						}
					]
				},
				mapbox: null
			}
		},
		mounted() {
			this.init_component()
		},
		methods: {
			async init_component() {
				this.mapbox = this.getMapBoxKey()

				this.tier_already_exists = false
				this.form_message = ""
				this.form = this.default_form

				this.countries = Countries[Vue.i18n.locale()].label
			},
			checkForm: async function () {
				if(!this.processing) {
					this.processing = true

					this.form_message = ""
					this.error = []

					if(this.form.tiers_moral == true) {
						if(!this.form.tiers_rs) { 
							this.error.push("Erreur rs") 
						}
					}
					else {
						// if(!this.form.tiers_firstname){
						// 	this.error.push("Erreur prénom") 
						// }
						if(!this.form.tiers_lastname){
							this.error.push("Erreur nom") 
						}
						if(!this.form.tiers_civility){
							this.error.push("Erreur civilité") 
						}
					}

					if(this.form.tiers_mail && !this.form.tiers_mail.match(this.regex_email)) {
						this.error.push('Erreur mail')
					}

					if(!this.form.tiers_country) {
						this.error.push("Erreur pays")
					}

					if(this.error.length == 0) {
						this.form.tiers_civility = Common.manageCivilities(this.form.tiers_civility)

	                    const params = cloneDeep(this.form)
	                    let new_tiers_id = null
						try {
							/* EDITION */
							if(this.form.tiers_id) {
								new_tiers_id = await this.editTiers(params)
								await this.$sync.runOptionalWhishlist(['tiers'])
							}
							/* AJOUT */
							else {
								new_tiers_id = await this.addTiers(params)
								// await this.$sync.runOptionalWhishlist(['tiers'])
                            	await this.$sync.force(true)
								new_tiers_id = this.$sync.replaceWithReplicated('tiers', new_tiers_id)
								if(this.add_contact){
									const newTiers = cloneDeep(params)
									const name = newTiers.tiers_moral == false ? newTiers.tiers_firstname + newTiers.tiers_lastname : this.contact_form.contact_firstname + this.contact_form.contact_lastname
									const newContact = {
										contact_address1: newTiers.tiers_address1,
										contact_address2: newTiers.tiers_address2,
										contact_civility: newTiers.tiers_moral == false ? newTiers.tiers_civility : this.contact_form.contact_civility,
										contact_country: newTiers.tiers_country,
										contact_firstname: newTiers.tiers_moral == false ? newTiers.tiers_firstname :  this.contact_form.contact_firstname,
										contact_lastname: newTiers.tiers_moral == false ?  newTiers.tiers_lastname :  this.contact_form.contact_lastname,
										contact_mail: newTiers.tiers_mail,
										contact_postalcode: newTiers.tiers_postalcode,
										contact_town: newTiers.tiers_town,
										contact_abbreviation: name ? name.slice(0,2).toUpperCase() : newTiers.tiers_alias,
									}
									const new_contact_id = await this.addContact(newContact)
									if(newTiers.phones) {
									for(let i = 0; i < newTiers.phones.length; i++) {
										if(newTiers.phones[i].phone_numero) {
											newTiers.phones[i].contact_id = new_contact_id
											newTiers.phones[i].tiers_id = null
											await this.addPhone(newTiers.phones[i])
										}
									}
								}
									await this.$sync.force(true, true)
									await this.addTiersContact(new_tiers_id, new_contact_id)
								}

							}
						}
						catch (e) {
							if (typeof(e) === 'string') {
								this.form_message = e
								this.failureToast(e)
							}
							else {
								console.error(e)
							}

							return null
						}

						this.processing = false

						if(this.form.tiers_moral == true) {
							this.ok(this.form.tiers_rs)
						}
						else {
							this.ok(this.form.tiers_lastname)
						}
		                
		                this.shutterPanel().close('ajout-tiers')
					} else {
						this.processing = false
						this.form_message = "formulaire.erreur_champs_non_remplis"
					}
				}
            },

			// Si on est pas en France, on ne recherche pas les code postaux français
			checkSearchPostalCode(country) {
				this.is_french = country == 75
			},

            /* Vérifie si un tiers de la même raison sociale (ou de la même combinaison nom/prenom si pas personne morale) existe déjà */
            async checkIdenticalTierRs() {
				this.tier_already_exists = false

				const rs = this.form.tiers_moral === true ? this.form.tiers_rs : `${this.form.tiers_lastname} ${this.form.tiers_firstname}`
				if(!rs) {
					return false
				}

				const count = await this.tierExists(rs, this.form.tiers_id)
				this.tier_already_exists = count > 0
            },

			addPhoneNumber() {
				const countries_iso = Countries[Vue.i18n.locale()].iso_3
				const ISO = countries_iso[parseInt(this.form.tiers_country)]

				this.form.phones.push({
					phone_indicatif: Indicatif[ISO],
					phone_numero: '',
					phone_type: this.getTrad('tiers.phone_type.fixe'),
					phone_country: ISO,
				})
			},
			
			resetTown() {
				this.form.tiers_town = ''
			},

			resetPostalcode() {
				this.form.tiers_postalcode = ''
			}
        },
		computed : {
			isOnline() {
				return window.navigator.onLine
			}
		},
		watch : {
			'form.tiers_country' (val) {
				const countries_iso = Countries[Vue.i18n.locale()].iso_3
				const ISO = countries_iso[parseInt(val)]
				for (let phone of this.form.phones) {
					if (!phone.phone_numero) {
						phone.phone_indicatif = Indicatif[ISO]
						phone.phone_country = ISO
					}
				}
				this.checkSearchPostalCode(val)
			}
		},
		components: {
			LoadingSpinner : () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
			ErrorAlert : () => import('GroomyRoot/components/Alert/ErrorAlert'),
			PhoneInput : () => import('GroomyRoot/components/Inputs/PhoneInput'),
            CountryInput : () => import('GroomyRoot/components/Inputs/CountryInput'),
            CurrencyInput : () => import('GroomyRoot/components/Inputs/CurrencyInput'),
            LocaleInput : () => import('@/components/Inputs/LocaleInput'),
            WarnAlert : () => import('GroomyRoot/components/Alert/WarnAlert'),
            EAddress : () => import('GroomyRoot/components/Utils/EAddress'),
			SearchInputCommune : () => import('@/components/Inputs/SearchInputCommune')
		}
	}
</script>